/* eslint-disable max-len */
import React, { Fragment } from 'react';
import NextSectionLink from '../../learningCenter/NextSectionLink';
import PropTypes from 'prop-types';

const EstimatedBreedValues = ({ match }) => (
  <Fragment>
    <h1 className="main-title">Breedplan</h1>
    <h2 className="caption" data-cy="genetic-breed-value-title">
      Genetic breed values
    </h2>
    <p className="intro">Breedplan is a genetic evaluation system for beef cattle that is used extensively internationally by suckler beef producers. Breedplan produces Estimated Breeding Values (EBVs) for a range of measurable production traits, using Best Linear Unbiased Prediction (BLUP) technology.</p>
    <div className="intro-divider"></div>
    <h4>TI – Terminal Index, related towards animal growth and beef characteristics</h4>
    <p className="standard-para">The Terminal Index indicates the genetic quality for breeding stock and predicted meat quality and value for beef. It includes traits for growth, marbling, steak size, fat depth, leanness. Click to the Breedplan data for each animal for the % ranking in comparison with the breed averages via the Ancestry tree link.</p>
    <h4>SRI – Self Replacing Index, related towards animal fertility, birthing and rearing characteristics</h4>
    <p className="standard-para">The Self-Replacing Index measures the genetic quality for breeding. It includes traits for easy birthing, birth and mature weight, milkiness, fertility. See Breedplan for each animal for the % ranking in comparison with the breed averages via the Ancestry tree link.</p>
    <h4>Top % Genetic Ranking</h4>
    <p className="standard-para">BeefBook combines the TI and SRI rankings to indicate where the animal stands in comparison with the breed from an overall standpoint. The breed is the pool of cattle recorded by the relevant breed society or a group of producers.  BeefBook guidelines for comparison and indicative value purposes are:</p>
    <ul className="seperated-list">
      <li>Top 10% is an exceptional animal across the spectrum of traits for the breed (Supreme)</li>
      <li>Top 20% is a very high-class animal across the range of genetic traits (Prime)</li>
      <li>Top 30% is a well-rounded animal with some outstanding traits (Classic)</li>
      <li>Top 40% is a well-balanced animal (Superior)</li>
      <li>50% is at the median for the breed as a whole (Standard)</li>
    </ul>
    <p></p>
    <NextSectionLink path={ `${match.url.substring(0, match.url.lastIndexOf('/'))}/marbling` }
      label="Marbling" />
  </Fragment>
);

EstimatedBreedValues.propTypes = {
  match: PropTypes.object.isRequired
};

export default EstimatedBreedValues;
