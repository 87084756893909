import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const Authentication = ({ match }) => (
  <Fragment>
    <h2 className="caption">Authentication</h2>
    <div className="intro-divider"></div>
    <p className="standard-para">BeefBook brings together available evidence to support the integrity of the scoring.  The main sources are: </p>
    <ul className="standard-bullet">
      <li>Certificates (eg. Pasture for Life, Cattle Health Certification Standards - CHeCS)</li>
      <li>Scientific research results (eg. Intra-muscular nutritional value) </li>
      <li>Independent professional opinions (eg. Fertility examination by a Vet) </li>
      <li>Official Proofs (eg. Animal passport for proof of ownership)</li>
      <li>Breed Society Registration Databases (eg. Animal ancestry and genetics)</li>
    </ul>
  </Fragment>
);

Authentication.propTypes = {
  match: PropTypes.object.isRequired
};

export default Authentication;
