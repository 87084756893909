import React, { Fragment } from 'react';
import NextSectionLink from '../../learningCenter/NextSectionLink';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const GoodBeefIndex = ({ match }) => (
  <Fragment>
    <h2 data-cy="goodbeef-index-title" className="caption">GoodBeef Index</h2>
    <div className="intro-divider"></div>
    <p className="standard-para">The GoodBeef Index comprehensively rates 3 aspects of quality, based on recognised standards of best practice for cattle breeding, rearing and finishing for good beef, namely:</p>
    <ul className="standard-bullet">
      <li>Production Quality</li>
      <li>Eating Quality</li>
      <li>Environment Quality </li>
    </ul>
    <p className="standard-para">Each aspect is measured through several factors. Each factor scores on a scale of 1 (lower) to 4 (higher). The aggregate score is out of 120. The Production, Eating and Environment aspects each score a maximum of 40.</p>
    <p className="standard-para">As a rule of thumb, a good beef animal, for eating or for breeding would be scoring 30+ in each of the 3 aspects of quality. The objective bring to produce a well balanced herd and beef output.</p>
    <NextSectionLink path={ `${match.url.substring(0, match.url.lastIndexOf('/'))}/genetic-value-ranking` }
      label="Genetic value ranking" />
  </Fragment>
);

GoodBeefIndex.propTypes = {
  match: PropTypes.object.isRequired
};

export default GoodBeefIndex;
