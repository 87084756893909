import React, { Fragment } from 'react';
import NextSectionLink from '../../learningCenter/NextSectionLink';
import PropTypes from 'prop-types';
import BeefMarblingInfo from '../../content/infoblocks/BeefMarblingInfo';

/* eslint-disable max-len */
const Marbling = ({ match }) => (
  <Fragment>
    <BeefMarblingInfo />
    <NextSectionLink path={ `${match.url.substring(0, match.url.lastIndexOf('/'))}/authentication` }
      label="Authentication" />
  </Fragment>
);

Marbling.propTypes = {
  match: PropTypes.object.isRequired
};

export default Marbling;
