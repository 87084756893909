import React from 'react';
import { Link } from 'react-router-dom';
import ShortArrow from '../util/icons/components/ShortArrow';
import './next-section-link.scss';
import PropTypes from 'prop-types';

const NextSectionLink = ({ path, label }) => (
  <div onClick={ () => window.scrollTo({ top: 0 })}>
    <Link className="next-section-link" to={ path }>
      <div className="arrow">
        <ShortArrow />
      </div>
      <p className="next-section-title">next section</p>
      <p className="text"> { label }</p>
    </Link>
  </div>
);

NextSectionLink.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};

export default NextSectionLink;
