import React, { Fragment } from 'react';
import NextSectionLink from '../../learningCenter/NextSectionLink';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const AboutBeefBook = ({ match }) => (
  <Fragment>
    <h1 className="main-title">a fair price</h1>
    <h2 className="caption">for an authenticated standard of quality</h2>
    <p className="intro">BeefBook is a resource for buyers and sellers of breeding animals and finished beef. It draws on available Breed Society rankings of genetic traits and beef quality measurements provided by the GoodBeef Index.</p>
    <div className="intro-divider"></div>
    <p className="standard-para">BeefBook acts as a <span className='highlight'>repository</span> for certifications and ancestry by officially recognised bodies, to enable buyers to verify the animal.</p>
    <p className="standard-para">BeefBook provides a <span className='highlight'>visual verification</span> of breeding animals, with a short 15 second video on physical structure.</p>
    <p className="standard-para">BeefBook links with the Breedplan system of <span className='highlight'>genetic value rankings</span>.</p>
    <p className="standard-para">BeefBook calls on the GoodBeef Index scoring, for a <span className='highlight'>consumer dimension</span> of good beef quality.</p>
    <NextSectionLink path={ `${match.url}/good-beef-index` }
      label="GoodBeef Index" />
  </Fragment>
);

AboutBeefBook.propTypes = {
  match: PropTypes.object.isRequired
};

export default AboutBeefBook;
