import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Route,
  Switch } from 'react-router-dom';
import '../../learningCenter/learning-center.scss';
import { sideMenuIsOpen } from '../../header/reducer';
import { setMobileMenuOpen } from '../../header/actions';
import BBAboutMenu from './BBAboutMenu';
import AboutBeefBook from './AboutBeefBook';
import GoodBeefIndex from './GoodBeefIndex';
import GeneticValueRanking from './GeneticValueRanking';
import Authentication from './Authentication';
import Breedplan from './BreedPlan';
import Marbling from './Marbling';

const mapStateToProps = state => ({
  sideMenuOpen: sideMenuIsOpen(state)
});

const mapDispatchToProps = dispatch => ({
  openMenu: () => dispatch(setMobileMenuOpen(true))
});

class LearningCenter extends Component {
  componentDidMount () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  render () {
    const className = this.props.sideMenuOpen ?
      'mobile-menu-visible' : null;
    return (
      <div id='learning-center' className={ className }>
        <Route path={`${this.props.match.url}`} render={
          (props) => <BBAboutMenu { ...props} />
        } />
        <div className="content">
          <Switch>
            <Route exact path={ this.props.match.url } render={
              (props) => <AboutBeefBook { ...props} />
            } />
            <Route path={`${this.props.match.url}/good-beef-index`} render={
              (props) => <GoodBeefIndex { ...props} />
            } />
            <Route path={`${this.props.match.url}/breedplan`} render={
              (props) => <Breedplan { ...props} />
            } />
            <Route path={`${this.props.match.url}/genetic-value-ranking`}
              render={
                (props) => <GeneticValueRanking { ...props} />
              } />
            <Route path={`${this.props.match.url}/marbling`}
              render={
                (props) => <Marbling { ...props} />
              } />
            <Route path={`${this.props.match.url}/authentication`} render={
              (props) => <Authentication { ...props} />
            } />
          </Switch>
        </div>
      </div>
    );
  }
}

LearningCenter.propTypes = {
  match: PropTypes.object.isRequired,
  openMenu: PropTypes.func,
  sideMenuOpen: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(LearningCenter);
