import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
// import EnvScienceMenu from './EnvScienceMenu';
// import '../../leaningCenter/learning-center-menu.scss';
import '../../learningCenter/learning-center-menu.scss';
import { setSideMenuOpen, setMobileMenuOpen } from '../../header/actions';
import ShortArrow from '../../util/icons/components/ShortArrow';

const mapDispatchToProps = dispatch => ({
  closeSideMenu: () => dispatch(setSideMenuOpen(false)),
  closeMobileMenu: () => dispatch(setMobileMenuOpen(false))
});

class BBAboutMenu extends Component {
  constructor (props) {
    super(props);
    this.handleSelection = this.handleSelection.bind(this);
  }
  handleSelection () {
    this.props.closeMobileMenu();
    window.scrollTo({ top: 0 });
  }
  render () {
    return (
      <ul className='learning-center-menu'
        data-cy="bb-about-menu">
        <li className="header">
          <span className="learning">learning</span>
          <span className="center">centre</span>
        </li>
        <li className="back" onClick={ this.props.closeSideMenu }>
          <ShortArrow />
          <span className='text'>Main Menu</span>
        </li>
        <li className='top-level' onClick={ this.handleSelection }>
          <NavLink exact to={`${this.props.match.url}`}
            className='learning-center-menu-item'
            activeClassName='active'>
            <div className="text">
              about
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
        <li className='top-level' onClick={ this.handleSelection }>
          <NavLink to={`${this.props.match.url}/good-beef-index`}
            className='learning-center-menu-item'
            activeClassName='active'>
            <div className="text">
              GoodBeef Index
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
        <li className='top-level' onClick={ this.handleSelection }>
          <NavLink to={`${this.props.match.url}/genetic-value-ranking`}
            className='learning-center-menu-item'
            activeClassName='active'>
            <div className="text">
              genetic value ranking
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
        <li className='top-level' onClick={ this.handleSelection }>
          <NavLink to={`${this.props.match.url}/breedplan`}
            className='learning-center-menu-item'
            activeClassName='active'>
            <div className="text">
              breedplan
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
        <li className='top-level' onClick={ this.handleSelection }>
          <NavLink to={`${this.props.match.url}/marbling`}
            className='learning-center-menu-item'
            activeClassName='active'>
            <div className="text">
              Marbling
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
        <li className='top-level' onClick={ this.handleSelection }>
          <NavLink to={`${this.props.match.url}/authentication`}
            className='learning-center-menu-item'
            activeClassName='active'>
            <div className="text">
              authentication
              <div className="line"></div>
            </div>
          </NavLink>
        </li>
      </ul>
    );
  }
}

BBAboutMenu.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  }),
  closeSideMenu: PropTypes.func.isRequired,
  closeMobileMenu: PropTypes.func.isRequired
};

export default connect(null, mapDispatchToProps)(BBAboutMenu);
