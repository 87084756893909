import React, { Fragment } from 'react';
import NextSectionLink from '../../learningCenter/NextSectionLink';
import PropTypes from 'prop-types';

/* eslint-disable max-len */
const GeneticValueRanking = ({ match }) => (
  <Fragment>
    <h2 className="caption" data-cy="genetic-value-ranking-title">
      Genetic value ranking
    </h2>
    <p className="intro">The <span className="highlight">Genetic Value</span> – Estimated Breed Value (EBV) is a Top % ranking for a specific pedigree animal in the breed. It scores each genetic trait and displays the ancestry for tracing inherited genetic characteristics.</p>
    <div className="intro-divider"></div>
    <p className="standard-para">The genetic data in Beefbook draws on the <span className="highlight">Breedplan</span> system of phenotypical genetic traits rankings, used internationally by cattle breeders and suckler herds.</p>
    <p className="standard-para">It will also draw on other sources of <span className="highlight">genetic value</span>, such as ultra-sound results for fat depth and muscle size and shape, DNA testing for specific genetic characteristics and the proof of parentage.</p>
    <NextSectionLink path={ `${match.url.substring(0, match.url.lastIndexOf('/'))}/breedplan` }
      label="Breedplan" />
  </Fragment>
);

GeneticValueRanking.propTypes = {
  match: PropTypes.object.isRequired
};

export default GeneticValueRanking;
